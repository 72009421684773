function copyEmbedCode() {
  var success = false;
  var em = document.querySelector("#embedcode");
  var ex = document.querySelector("#copycontent");
  var string = em.outerHTML;
  ex.setAttribute('value', string);


  if( ex.value.length > 0 ) {
    var en = document.querySelector("#copycontent");
    en.select();
    document.execCommand("copy");
    success = true;
  }

  return success;
}

function copyURLCode() {
  var success = false;
  var ex = document.querySelector("#copycontent-url");



  if( ex.value.length > 0 ) {
    var en = document.querySelector("#copycontent-url");
    en.select();
    document.execCommand("copy");
    success = true;
  }

  return success;
}


function createMediaElement(source) {
  el = document.querySelector('.mediaWrapper');

  if( el === null ) {
    var loc = document.querySelector(".banner");
    var newEl = document.createElement("div");
    var video ='<i class="far fa-window-close"></i><video class="mejs__player" preload="true" width="640" height="360"><source type="video/mp4" src="'+source.target.dataset.info+'"></video><div class="self-close"></div>';

    newEl.className = "mediaWrapper";

    document.body.insertBefore( newEl, loc );
    document.querySelector('.mediaWrapper').innerHTML = video;

    var videoEl = document.querySelector('.mejs__player');
    
    if( videoEl !== null ) {
      console.log('videoEl exists');
      // videoEl.MediaElementPlayer({
      //   pluginPath: "/path/to/shims/",
      //   success: function(mediaElement, originalNode, instance) {
      //     // do things
      //   }
      // })

      var player = new MediaElementPlayer(videoEl, {
        pluginPath: "/path/to/shims/",
      // When using `MediaElementPlayer`, an `instance` argument
      // is available in the `success` callback
        success: function(mediaElement, originalNode, instance) {
          // do things
        }
      });
      
    }
  }
}

function closeAndDestroyVideo() {
  video = document.querySelector('.mejs__player');
  elem = document.querySelector('.mediaWrapper');
  video.remove();
  elem.parentNode.removeChild(elem);

}

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          $('.flexslider').flexslider({});

          var el = $(".fms-select");
          if( el.length > 0 ) {
            $('.fms-select').multiSelect();
          }

          /**** carcass image map *****/ 
          var carcassImage = $('#carcass-image');
          if(carcassImage.length > 0) {

            // setup mobile button handling
            $('.carcass-mobile-buttons a').click(function(e) {
              e.preventDefault();
              var button = $(this);
              var wasActive = button.hasClass('active');
              
              if(!wasActive) {
                carcassImage.mapster('set', null, button.data('cut'));
                $('.carcass-mobile-buttons a.active').removeClass('active');
                button.addClass('active');
              } else {
                carcassImage.mapster('set', null, button.data('cut'));
                button.removeClass('active');
              }
            })

            // setup the settings for the mapster
            var mapAreaSettings = [];
            carcassImage.siblings('map').find('area').each(function(index) {
              var mapArea = $(this);
              
                mapAreaSettings.push({
                  key:mapArea.data('cut'),
                  fillColor: mapArea.data('cut-colour').replace('#', ''),
                  altImage: mapArea.data('cut-image')
                })
            });
  
            // initial mapster
            carcassImage.mapster({
              mapKey: 'data-cut',
              fillColor: 'ff0000',
              fillOpacity: 0.8,
              areas: mapAreaSettings,
              singleSelect: true,
              onStateChange: function(event) {
                // handle the content display when areas are clicked

                // if no areas are selected show default content
                if(event.state == 'select' && !event.selected) {
                  console.log(event, carcassImage.mapster('get'));
                  setTimeout(function () {
                    if(carcassImage.mapster('get') == '') {
                      $(".carcass-content-wrapper.active").removeClass('active').fadeOut(200, function() {
                        $(".carcass-content__default").fadeIn().addClass('active');
                      });
                    }
                  }, 20);
                }

                // if an area is selected, show its content
                if(event.state == 'select' && event.selected) {
                  $(".carcass-content-wrapper.active").removeClass('active').fadeOut(200, function() {
                    $(".carcass-content-wrapper[data-carcass-content='"+event.key+"']").fadeIn(200).addClass('active');
                  });
                }
              }
            });
  
            // add resize listener
            $(window).bind('resize',carcassOnWindowResize);
          }
          

          var resizeTime = 100;     // total duration of the resize effect, 0 is instant
          var resizeDelay = 100;    // time to wait before checking the window size again
                          // the shorter the time, the more reactive it will be.
                          // short or 0 times could cause problems with old browsers.
                          

          // Resize the map to fit within the boundaries provided

          function carcassResize() {
            var image =  $('#carcass-image'),
              newHeight=0,
              parentWrapper = image.closest('.carcass-image'),
              newWidth = parentWrapper.width()

          
            image.mapster('resize',newWidth,newHeight,resizeTime);   
          }

          // Track window resizing events, but only actually call the map resize when the
          // window isn't being resized any more

          function carcassOnWindowResize() {
          
            var curWidth = $(window).width(),
                curHeight = $(window).height(),
                checking=false;
            if (checking) {
              return;
            }
            checking = true;
            window.setTimeout(function() {
              var newWidth = $(window).width(),
                  newHeight = $(window).height();
              if (newWidth === curWidth &&
                  newHeight === curHeight) {
                  carcassResize(); 
              }
              checking=false;
            },resizeDelay );
          }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        $('.copy-link').on('click', function(e) {
          // e.preventDefault();
          var copyevent = copyEmbedCode();
          console.log(copyevent);
          if( copyevent === true ) {
            $(this).html('Copied');
          }
          // console.log('copy event triggered');
        });

        $('.copy-link-url').on('click', function(e) {
          // e.preventDefault();
          var copyevent = copyURLCode();
          console.log(copyevent);
          if( copyevent === true ) {
            $(this).html('Copied');
          }
          // console.log('copy event triggered');
        });

        // videoTrigger  = document.querySelectorAll('.video-trigger123123');
        
        // for (var i = 0, len = videoTrigger.length; i < len; i++) {
        //   videoTrigger[i].addEventListener('click', function(e) {
        //     createMediaElement(e);

        //     closeTrigger = document.querySelector('.fa-window-close');
        //     closeTrigger.addEventListener('click', function(e) {
        //       closeAndDestroyVideo(e);
        //     });
        //   }, false);
        // }
        // console.log(readmelater_ajax.ajax_url);
        $.ajax({
          type : "post",
          dataType : "json",
          url : readmelater_ajax.ajax_url,
          data : {action: "pageview"},
          success: function(response) {
            console.log('success');
            console.log(response);
          },
          error: function(response) {
            console.log('Failure');
            console.log(response);
          }
        });


        videoplayer = document.querySelector('.load-video-player');

        var vidplayer = new MediaElementPlayer(videoplayer, {
          pluginPath: "/path/to/shims/",
          videoWidth: '100%',
          videoHeight: '100%',
          enableAutosize: true,
          success: function(mediaElement, originalNode, instance) {
          }
        });


        var filterSelect = document.querySelector('#fms-select');

        if( typeof filterselect !== 'undefined' ) {

          filterSelect.addEventListener('change', function(e) {
            var selected = e.target[e.target.selectedIndex];
            
            var index = document.querySelectorAll('.type-container');
            
            if( selected.value !== "All") {

              for( var n = 0; n < index.length; n++) {
                // console.log(selected.value);
                var x = index[n].dataset.type;
                if( x.includes(selected.value) !== true ) {
                  // console.log('things');
                  index[n].style.display = 'none';
                } else {
                  // console.log('other things');
                  index[n].style.display = 'block';
                }
                
              }

            } else {

                for(var l = 0; l < index.length; l++) {
                  index[l].style.display = 'block';
                }
            }

          });
          
        }
        

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
